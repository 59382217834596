import $ from 'jquery';

import './scss/style.scss';

let data = [];
let view = 'setlist';
let currentAudio = -1;
let loaded = 0;

const $data = $('#data input[type=hidden');
const hasData = $data && $data.length;
const $loading = $('#loading');
const hasLoading = $loading && $loading.length;
const $loadingValue = $loading.find('.value');
const hasLoadingValue = $loadingValue && $loadingValue.length;
const $loadingPercentage = $loading.find('.percentage');
const hasLoadingPercentage = $loadingPercentage && $loadingPercentage.length;
const $start = $('#start');
const hasStart = $start && $start.length;
const $showSetlist = $('#show-setlist');
const hasShowSetlist = $showSetlist && $showSetlist.length;
const $showPlaylist = $('#show-playlist');
const hasShowPlaylist = $showPlaylist && $showPlaylist.length;
const $setlist = $('#setlist');
const hasSetlist = $setlist && $setlist.length;
const $playlist = $('#playlist');
const hasPlaylist = $playlist && $playlist.length;

const getData = () => {
  if (hasData) {
    $data.each((index) => {
      const $current = $data.eq(index);

      if (
        $current &&
        $current.attr('nome') &&
        $current.attr('nome').length &&
        $current.attr('compositor') &&
        $current.attr('compositor').length &&
        $current.attr('interprete') &&
        $current.attr('interprete').length &&
        $current.attr('audio') &&
        $current.attr('audio').length
      ) {
        data.push({
          name: $current.attr('nome'),
          composer: $current.attr('compositor'),
          interpreter: $current.attr('interprete'),
          file: $current.attr('audio'),
        });
      }
    });
  }

  return data;
};

const addMusicToPlaylist = (index) => {
  const $music = $setlist.find('.music-item').eq(index);

  if ($music && $music.length && hasPlaylist) {
    const $clone = $music.clone();

    $music.addClass('hide');
    $playlist.append($clone);
  }
};

const addRandomMusic = () => {
  const possibilities = [...Array(data.length).keys()].filter((item) => item !== currentAudio);
  const random = Math.floor(Math.random() * possibilities.length);
  const $audio = $setlist.find('.music-item').eq(possibilities[random]).find('audio');

  currentAudio = possibilities[random];
  addMusicToPlaylist(currentAudio);
  $audio.get(0).play();
};

const onClickStart = () => {
  addRandomMusic();
  $start.addClass('hide');
};

const onDocumentReady = () => {
  data = getData();

  if (
    data &&
    data.length &&
    hasSetlist &&
    hasPlaylist &&
    hasShowSetlist &&
    hasShowPlaylist &&
    hasStart
  ) {
    data.forEach((music, index) => {
      const $musicItemDOM = $(`<button class="music-item" index="${index}"><span class="icon" /></button>`);
      const $musicNameDOM = $(`<h3 class="music-name">${music.name}</h3>`);
      const $musicComposerDOM = $(`<p class="music-composer">(${music.composer})</p>`);
      const $musicInterpreterDOM = $(`<p class="music-interpreter">Intérprete – <span>${music.interpreter}</span></p>`);
      const $audioDOM = $(`<audio controls><source src="assets/audios/${music.file}" type="audio/mpeg" /></audio>`);

      $musicItemDOM.click(() => { addMusicToPlaylist(index); });
      $audioDOM.on('ended', () => {
        $playlist.find('.music-item').first().remove();

        if (!$playlist.find('.music-item').length) {
          addRandomMusic();
        } else {
          const playIndex = parseInt($playlist.find('.music-item').first().attr('index'));

          $setlist.find('.music-item').eq(playIndex).find('audio').get(0).play();
          currentAudio = index;
        }

        $setlist.find('.music-item').eq(index).removeClass('hide');
      });

      if (hasLoading) {
        $audioDOM.on('canplay', () => {
          loaded += 1;

          let current = 100 * (loaded / data.length);

          if (hasLoadingValue) {
            $loadingValue.css('width', `${current}%`);
          }

          if (hasLoadingPercentage) {
            $loadingPercentage.text(`${current.toFixed(2)}%`);
          }

          if (current >= 100) {
            $loading.addClass('hide');
          }
        });
      }

      $musicItemDOM.append($musicNameDOM, $musicComposerDOM, $musicInterpreterDOM, $audioDOM);
      $setlist.append($musicItemDOM);
    });

    $showSetlist.click(() => {
      if (view === 'setlist') {
        return;
      }

      $setlist.addClass('show');
      $setlist.scrollTop(0);
      $playlist.removeClass('show');
      view = 'setlist';
    });

    $showPlaylist.click(() => {
      if (view === 'playlist') {
        return;
      }

      $playlist.addClass('show');
      $playlist.scrollTop(0);
      $setlist.removeClass('show');
      view = 'playlist';
    });

    $start.click(onClickStart);

    document.addEventListener('gesturestart', (event) => {
      event.preventDefault();
      document.body.style.zoom = 0.99;
    });
    document.addEventListener('gesturechange', (event) => {
      event.preventDefault();
      document.body.style.zoom = 0.99;
    });
    document.addEventListener('gestureend', (event) => {
      event.preventDefault();
      document.body.style.zoom = 1;
    });
  }
};

$(document).ready(onDocumentReady);
